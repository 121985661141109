import React, { useState } from 'react'
import Layout from '../../../components/layouts'
import Banner from '../../../components/features/Banner'
import SEO from '../../../components/common/SEO';
import ReactWOW from 'react-wow'
import FeatureCards from "../../../components/features/FeatureCards"

export const query = graphql`
  query ScratchCards {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        twitterUsername
      }
    }
  }
`

export default ({ data }) => {
    const [toggler, setToggler] = useState(false)
    const lightboxProps = {
        toggler: toggler,
        setToggler: setToggler,
        sources: ["https://www.youtube.com/watch?v=xE2yXvWB038"],
    }

    const bannerProps = {
        title: 'Scratch Cards',
        subtitle: `Scratch cards are an optional add-on within our Recognition Program to reward people who are exhibiting top-rate performance. Order custom cards with your logo and messaging and distribute them to supervisors who can hand them when they see your workers doing something exemplary, such as: following protocols to a tee, looking out for the new guy, consistently rocking their PPE or going out of the way to help others.`,
        featureName: 'behaviourObservations',
        rightButtonText: 'Why Certificate Tracking Software?',
        rightButtonSrc: 'https://www.youtube.com/watch?v=xE2yXvWB038',
        mockupImg: '/features/scratch-cards/scratch-cards-banner.png'
    }

    const seo = {
        title: 'WorkHub | Safety Scratch Cards Create Custom Scratch Off Cards',
        description: 'Order customized scratch cards to handout when you see employees helping others, wearing ppe and following safety protocols.',
        image: '/features/scratch-cards/social-card.jpg',
        // pathname,
        // article
    }

    const featureIndex = [
        {
            title: "Incentivize",
            para: "Hand out a Scratch Card whenever an employee does something exemplary such as: helping new employees, following procedures to a tee, or treating a customer exceedingly well.",
        },
        {
            title: "Customize",
            para: "Scratch Cards can be customized with your company branding / logo.",
        },
        {
            title: "Value Sets",
            para: "We offer 3 different point value sets (standard, high and precious) which award a higher or lower range of points depending on what works best for your budget.",
        },
        {
            title: "Redemption",
            para: "Scratch cards can be built into an existing rewards inventory or companies can purchase points for Workhub’s rewards system.",
        },
    ]

    return (
        <Layout {...lightboxProps}>
            <SEO {...seo} {...data.site} />
            <Banner {...bannerProps} {...lightboxProps} />


            <main className="feature-main">
                <section className="feature-section">
                    <div className="container">
                        <div className="row switch-children">
                            <div className="col-lg-6 feature-img">
                                <ReactWOW animation="fadeInLeft" delay="0.9s">
                                    <img
                                        className="feature-img-right img-exception"
                                        src="/features/scratch-cards/scratch-cards-wallet2.png"
                                        alt="Scratch Cards Wallet"
                                    />
                                </ReactWOW>
                            </div>

                            <div className="col-lg-6 feature-text">
                                <h2>
                                    Spread the love
                </h2>
                                <p>Each set costs $15 and has 60 cards that are enclosed in a handsome leather wallet. You can tie the cards into your existing rewards inventory or purchase points for our built-in rewards system where we offer direct-to-employee shipping and a huge variety of gear, apparel, and swag. We offer 3 different point value sets (standard, high and precious) which award a higher or lower range of points depending on what works best for your budget. The point values are randomized, which makes scratching suspenseful and exciting. </p>

                            </div>
                        </div>
                    </div>
                </section>

                <section className="feature-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 feature-text">
                                <h2>
                                    Cash em’ in
                </h2>
                                <p>The cards have a scratch box that reveal the amount of points the card is worth, along with a code. The cards are company specific and hold no value outside of your organization. Workers add the points to our system by clicking ‘Redeem Scratch Card’ in the Recognition tile on the Worker Dashboard. </p>

                            </div>

                            <div className="col-lg-6 feature-img">
                                <ReactWOW animation="fadeInLeft" delay="0.9s">
                                    <img
                                        className="feature-img-left"
                                        src="/features/scratch-cards/scratch-cards-mobile.png"
                                        alt="Reedem Scratch Card View on Mobile"
                                    />
                                </ReactWOW>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="feature-section">
                    <div className="container">
                        <div className="row switch-children">
                            <div className="col-lg-6 feature-img">
                                <ReactWOW animation="fadeInLeft" delay="0.9s">
                                    <img
                                        className="feature-img-right"
                                        src="/features/scratch-cards/scratch-cards-tablet.png"
                                        alt="Rewards catalogue on Tablet view"
                                    />
                                </ReactWOW>
                            </div>

                            <div className="col-lg-6 feature-text">
                                <h2>
                                    Consider giving out a scratch card when employees:
                </h2>

                                <ul className="checklist">
                                    <li>treat a customer exceedingly well.</li>
                                    <li>pick, mop or sweep up someone else’s mess.</li>
                                    <li>fix a small but potentially embarrassing defect.</li>
                                    <li>keep an upbeat attitude on a difficult day.</li>
                                    <li>exhibit any behaviour that makes your heart to burst with pride.</li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </section>



                {/* FEATURE OVERVIEW */}

                <section className="feature-overview-container">
                    <h2>Overview</h2>

                    <div className="feature-overview-grid">
                        {featureIndex.map(item => (
                            <FeatureCards title={item.title} para={item.para} />
                        ))}
                    </div>
                </section>


            </main>

        </Layout>
    )
}